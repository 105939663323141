import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ImageProcessor from "./ImageProcessor.jsx";
import { getBackdropImages } from "../../api/statics.jsx";
import { postBackdropSwap, pollForTaskFinish } from "../../api/modelsApi.jsx";
import { taskStatus } from "../../config/constants.js";
import { AlertMessage } from "../Utils/AlertMessage.jsx";
import { updateTabState } from "../../redux/studioSlice";

const BackgroundSwap = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.studio.BG_SWAP);

  const [selectedImage, setSelectedImage] = useState(
    state?.selectedImage || {},
  );
  const [selectedBackground, setSelectedBackground] = useState(
    state?.selectedBackground || {},
  );
  const [outputImage, setOutputImage] = useState(state?.outputImage || null);
  const [warningMessage, setWarningMessage] = useState(
    state?.warningMessage || "",
  );
  const [backgrounds, setBackgrounds] = useState(state?.backgrounds || []);
  const [isDownloadReady, setIsDownloadReady] = useState(
    state?.isDownloadReady || false,
  );
  const [alertVisible, setAlertVisible] = useState(
    state?.alertVisible || false,
  );
  const [isRunning, setIsRunning] = useState(state?.isRunning || false);

  useEffect(() => {
    let timer;
    if (alertVisible) {
      timer = setTimeout(() => {
        setAlertVisible(false);
        dispatch(
          updateTabState({
            tabId: "BG_SWAP",
            newState: { alertVisible: false },
          }),
        );
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [alertVisible, dispatch]);

  useEffect(() => {
    const fetchBackgrounds = async () => {
      if (backgrounds.length === 0) {
        try {
          const backdropImageList = await getBackdropImages();
          const backgroundUrls = backdropImageList.map((image) => image.file);
          setBackgrounds(backgroundUrls);
          dispatch(
            updateTabState({
              tabId: "BG_SWAP",
              newState: { backgrounds: backgroundUrls },
            }),
          );
        } catch (error) {
          console.error("Error fetching backgrounds:", error);
          setWarningMessage("Failed to load backgrounds.");
          setAlertVisible(true);
          dispatch(
            updateTabState({
              tabId: "BG_SWAP",
              newState: {
                warningMessage: "Failed to load backgrounds.",
                alertVisible: true,
              },
            }),
          );
        }
      }
    };
    fetchBackgrounds();
  }, [backgrounds, dispatch]);

  const handleImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const blobImage = event.target.files[0];
      const newSelectedImage = {
        blobImage: blobImage,
        blobImageUrl: URL.createObjectURL(blobImage),
      };
      setSelectedImage(newSelectedImage);
      setOutputImage(null);
      setIsDownloadReady(false);
      setWarningMessage("");
      setAlertVisible(false);
      dispatch(
        updateTabState({
          tabId: "BG_SWAP",
          newState: {
            selectedImage: newSelectedImage,
            outputImage: null,
            isDownloadReady: false,
            warningMessage: "",
            alertVisible: false,
          },
        }),
      );
    }
  };

  const handleBackgroundSelect = (background) => {
    setSelectedBackground(background);
    setWarningMessage("");
    setAlertVisible(false);
    dispatch(
      updateTabState({
        tabId: "BG_SWAP",
        newState: {
          selectedBackground: background,
          warningMessage: "",
          alertVisible: false,
        },
      }),
    );
  };

  const handleRun = async () => {
    if (isRunning) {
      const message = "Task already in progress.";
      setAlertVisible(true);
      dispatch(
        updateTabState({ tabId: "BG_SWAP", newState: { alertVisible: true } }),
      );
      console.log(message);
      return;
    }
    setIsRunning(true);
    dispatch(
      updateTabState({ tabId: "BG_SWAP", newState: { isRunning: true } }),
    );
    try {
      const backgroundSwapResponse = await postBackdropSwap(
        selectedBackground,
        selectedImage.blobImage,
      );
      const taskId = backgroundSwapResponse.task_id;
      let statusObj = await pollForTaskFinish(taskId);
      if (
        statusObj.status === taskStatus.FAILED ||
        statusObj.status === taskStatus.EMPTY_OUTPUT
      ) {
        throw new Error("Failed to process image!");
      } else if (statusObj.status === taskStatus.SUCCESS) {
        setOutputImage(statusObj.output);
        setIsDownloadReady(true);
        dispatch(
          updateTabState({
            tabId: "BG_SWAP",
            newState: {
              outputImage: statusObj.output,
              isDownloadReady: true,
            },
          }),
        );
      } else {
        throw new Error(`Unknown task status: ${statusObj.status}`);
      }
    } catch (error) {
      setWarningMessage(error.message);
      setAlertVisible(true);
      dispatch(
        updateTabState({
          tabId: "BG_SWAP",
          newState: {
            warningMessage: error.message,
            alertVisible: true,
          },
        }),
      );
    } finally {
      setIsRunning(false);
      dispatch(
        updateTabState({ tabId: "BG_SWAP", newState: { isRunning: false } }),
      );
    }
  };

  return (
    <>
      <ImageProcessor
        title="Backgrounds"
        images={backgrounds}
        uploadTitle="Photoshoot"
        onImageSelect={handleBackgroundSelect}
        onImageUpload={handleImageUpload}
        onRun={handleRun}
        selectedImage={selectedImage}
        selectedFilter={selectedBackground}
        appliedFilter={outputImage}
        isRunning={isRunning}
        isDownloadReady={isDownloadReady}
        type_run={"swap backdrop"}
      />
      {alertVisible && warningMessage && (
        <AlertMessage
          message={warningMessage}
          onClose={() => {
            setAlertVisible(false);
            dispatch(
              updateTabState({
                tabId: "BG_SWAP",
                newState: { alertVisible: false },
              }),
            );
          }}
        />
      )}
    </>
  );
};

export default BackgroundSwap;
