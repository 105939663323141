import { ArrowDown, ArrowRight, Plus } from "lucide-react";
import React, { useState } from "react";
import "./faceswap.css";

const FaceSwap = () => {
  const [modelImage, setModelImage] = useState(null);
  const [faceImage, setFaceImage] = useState(null);

  const handleModelImageUpload = (event) => {
    setModelImage(event.target.files[0]);
  };

  const handleFaceImageUpload = (event) => {
    setFaceImage(event.target.files[0]);
  };

  const handleFaceSwap = () => {
    // Implement the face swapping logic here
    console.log("Face swap initiated");
  };

  return (
    <div className="flex flex-col mt-24 items-center border-2 rounded-2xl mx-12 pt-12 h-[700px]  shadow-xl ">
      <h1 className="text-2xl text-[#1D3A72] font-bold mb-16">FACE SWAP</h1>
      <div className="flex justify-center items-center gap-24">
        <div className="flex flex-col items-center">
          <h2 className="text-lg font-medium mb-4">Model Image</h2>
          <div className="border border-gray-300 rounded-md p-4 flex flex-col items-center justify-center w-64 h-64">
            {modelImage ? (
              <img
                src={URL.createObjectURL(modelImage)}
                alt="Model"
                className="max-w-full max-h-full object-contain"
              />
            ) : (
              <div className="text-gray-400">
                Select a file or drag and drop here
                <br />
                JPG, PNG or WEBP files are supported
              </div>
            )}
            <input
              type="file"
              className="hidden"
              onChange={handleModelImageUpload}
            />
            <button
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={() =>
                document.querySelector('input[type="file"]').click()
              }
            >
              SELECT FILE
            </button>
          </div>
        </div>
        <Plus color="gray" size={32} className="" />
        <div className="flex flex-col items-center">
          <h2 className="text-lg font-medium mb-4">Face Image</h2>
          <div className="border border-gray-300 rounded-md p-4 flex flex-col items-center justify-center w-64 h-64">
            {faceImage ? (
              <img
                src={URL.createObjectURL(faceImage)}
                alt="Face"
                className="max-w-full max-h-full object-contain"
              />
            ) : (
              <div className="text-gray-400">
                Select a file or drag and drop here
                <br />
                JPG, PNG or WEBP files are supported
              </div>
            )}
            <input
              type="file"
              className="hidden"
              onChange={handleFaceImageUpload}
            />
            <button
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={() =>
                document.querySelector('input[type="file"]').click()
              }
            >
              SELECT FILE
            </button>
          </div>
        </div>
        <ArrowRight color="gray" size={32} className=" arrow-right" />
        <ArrowDown color="gray" size={32} className=" arrow-down" />
        <div className="flex flex-col items-center">
          <h2 className="text-lg font-medium mb-4">Swapped Image</h2>
          <div className="border border-gray-300 rounded-md p-4 flex flex-col items-center justify-center w-64 h-64">
            {modelImage && faceImage ? (
              <img
                src="/api/placeholder/400/320"
                alt="Swapped"
                className="max-w-full max-h-full object-contain"
              />
            ) : (
              <div className="text-gray-400">
                Nothing to see here yet!
                <br />
                Start by uploading your first image for swapping.
              </div>
            )}
          </div>
        </div>
      </div>
      <button
        className="mt-8 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        onClick={handleFaceSwap}
      >
        SWAP FACES
      </button>
    </div>
  );
};

export default FaceSwap;
