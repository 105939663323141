import React, { useState } from "react";
import { Plus, X } from "lucide-react";

const GarmentForm = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    size: "",
    age: "",
    occasion: "",
    fabric: "",
    customFields: [],
  });

  const [customField, setCustomField] = useState({ key: "", value: "" });
  const [errors, setErrors] = useState({});

  const sizes = ["Small", "Medium", "Large", "XL"];
  const ageRanges = [
    "1 - 2",
    "3 - 5",
    "5 - 8",
    "9 - 12",
    "13 - 17",
    "18 - 23",
    "24 - 30",
    "30+",
  ];
  const occasions = ["Casual", "Formal", "Party Wear"];
  const fabrics = ["Cotton", "Polyester"];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    // Clear error when field is changed
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleCustomFieldChange = (e) => {
    const { name, value } = e.target;
    setCustomField((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addCustomField = () => {
    if (customField.key && customField.value) {
      setFormData((prev) => ({
        ...prev,
        customFields: [...prev.customFields, { ...customField }],
      }));
      setCustomField({ key: "", value: "" });
    }
  };

  const removeCustomField = (index) => {
    setFormData((prev) => ({
      ...prev,
      customFields: prev.customFields.filter((_, i) => i !== index),
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.size) newErrors.size = "Size is required";
    if (!formData.age) newErrors.age = "Age range is required";
    if (!formData.occasion) newErrors.occasion = "Occasion is required";
    if (!formData.fabric) newErrors.fabric = "Fabric is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit(formData);
    }
  };

  return (
    <div className="bg-white rounded-lg border border-purple-200 p-6">
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Size Selection */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Size *
          </label>
          <div className="flex flex-wrap gap-3">
            {sizes.map((size) => (
              <label key={size} className="flex items-center">
                <input
                  type="radio"
                  name="size"
                  value={size}
                  checked={formData.size === size}
                  onChange={handleInputChange}
                  className="hidden"
                />
                <span
                  className={`px-4 py-2 rounded-full cursor-pointer transition-colors ${
                    formData.size === size
                      ? "bg-purple-600 text-white"
                      : "bg-gray-100 hover:bg-gray-200"
                  }`}
                >
                  {size}
                </span>
              </label>
            ))}
          </div>
          {errors.size && (
            <p className="mt-1 text-sm text-red-600">{errors.size}</p>
          )}
        </div>

        {/* Age Range */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Age Range *
          </label>
          <select
            name="age"
            value={formData.age}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
          >
            <option value="">Select age range</option>
            {ageRanges.map((range) => (
              <option key={range} value={range}>
                {range}
              </option>
            ))}
          </select>
          {errors.age && (
            <p className="mt-1 text-sm text-red-600">{errors.age}</p>
          )}
        </div>

        {/* Occasion */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Occasion *
          </label>
          <select
            name="occasion"
            value={formData.occasion}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
          >
            <option value="">Select occasion</option>
            {occasions.map((occasion) => (
              <option key={occasion} value={occasion}>
                {occasion}
              </option>
            ))}
          </select>
          {errors.occasion && (
            <p className="mt-1 text-sm text-red-600">{errors.occasion}</p>
          )}
        </div>

        {/* Fabric */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Fabric *
          </label>
          <select
            name="fabric"
            value={formData.fabric}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
          >
            <option value="">Select fabric</option>
            {fabrics.map((fabric) => (
              <option key={fabric} value={fabric}>
                {fabric}
              </option>
            ))}
          </select>
          {errors.fabric && (
            <p className="mt-1 text-sm text-red-600">{errors.fabric}</p>
          )}
        </div>

        {/* Custom Fields */}
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <h3 className="text-sm font-medium text-gray-700">
              Additional Details
            </h3>
          </div>

          {/* Custom Fields List */}
          {formData.customFields.map((field, index) => (
            <div key={index} className="flex items-center gap-2">
              <div className="flex-1 p-3 bg-gray-50 rounded-md">
                <span className="font-medium">{field.key}:</span> {field.value}
              </div>
              <button
                type="button"
                onClick={() => removeCustomField(index)}
                className="p-1 text-gray-400 hover:text-red-500"
              >
                <X size={20} />
              </button>
            </div>
          ))}

          {/* Add Custom Field */}
          <div className="flex gap-2">
            <input
              type="text"
              name="key"
              value={customField.key}
              onChange={handleCustomFieldChange}
              placeholder="Field name"
              className="flex-1 p-2 border border-gray-300 rounded-md"
            />
            <input
              type="text"
              name="value"
              value={customField.value}
              onChange={handleCustomFieldChange}
              placeholder="Value"
              className="flex-1 p-2 border border-gray-300 rounded-md"
            />
            <button
              type="button"
              onClick={addCustomField}
              disabled={!customField.key || !customField.value}
              className="p-2 text-purple-600 hover:bg-purple-50 rounded-md disabled:text-gray-400 disabled:hover:bg-transparent"
            >
              <Plus size={20} />
            </button>
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-purple-700 transition-colors"
        >
          Save Details
        </button>
      </form>
    </div>
  );
};

export default GarmentForm;
