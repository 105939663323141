import React, { useState, useRef, useEffect } from "react";
import { Pen, Eraser, Undo, Redo, Eye, Download } from "lucide-react";

const MaskingTool = ({ imageUrl, width = 300 }) => {
  const [isMaskMode, setIsMaskMode] = useState(false);
  const [isErasing, setIsErasing] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);
  const [maskHistory, setMaskHistory] = useState([]);
  const [currentHistoryIndex, setCurrentHistoryIndex] = useState(-1);
  const [maskColor] = useState("rgba(220, 38, 38, 0.5)");
  const [showPreview, setShowPreview] = useState(false);

  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const lastPos = useRef({ x: 0, y: 0 });

  useEffect(() => {
    const canvas = canvasRef.current;
    const image = imageRef.current;

    if (canvas && image) {
      // Wait for image to load
      image.onload = () => {
        canvas.width = width;
        canvas.height = image.offsetHeight * (width / image.offsetWidth);

        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        setMaskHistory([canvas.toDataURL()]);
        setCurrentHistoryIndex(0);
      };

      // Set crossOrigin to anonymous to prevent tainted canvas
      image.crossOrigin = "anonymous";
    }
  }, [imageUrl, width]);

  const getCanvasPoint = (e, canvas) => {
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    return {
      x: (e.clientX - rect.left) * scaleX,
      y: (e.clientY - rect.top) * scaleY,
    };
  };

  const setupDrawingContext = (ctx) => {
    if (isErasing) {
      ctx.globalCompositeOperation = "destination-out";
      ctx.strokeStyle = "rgba(0,0,0,1)";
      ctx.lineWidth = 25;
    } else {
      ctx.globalCompositeOperation = "source-over";
      ctx.strokeStyle = maskColor;
      ctx.lineWidth = 10;
    }
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
  };

  const startDrawing = (e) => {
    if (!isMaskMode) return;

    const canvas = canvasRef.current;
    const point = getCanvasPoint(e, canvas);

    const ctx = canvas.getContext("2d");
    ctx.beginPath();
    ctx.moveTo(point.x, point.y);
    setupDrawingContext(ctx);

    lastPos.current = point;
    setIsDrawing(true);
  };

  const draw = (e) => {
    if (!isDrawing || !isMaskMode) return;

    const canvas = canvasRef.current;
    const point = getCanvasPoint(e, canvas);

    const ctx = canvas.getContext("2d");
    setupDrawingContext(ctx);

    // Smooth line drawing using quadratic curves
    const xc = (point.x + lastPos.current.x) / 2;
    const yc = (point.y + lastPos.current.y) / 2;

    ctx.quadraticCurveTo(lastPos.current.x, lastPos.current.y, xc, yc);
    ctx.stroke();

    lastPos.current = point;
  };

  const stopDrawing = () => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.closePath();
    setIsDrawing(false);

    // Save state
    const imageData = canvas.toDataURL();
    setMaskHistory((prev) => [
      ...prev.slice(0, currentHistoryIndex + 1),
      imageData,
    ]);
    setCurrentHistoryIndex((prev) => prev + 1);
  };

  const handleUndo = () => {
    if (currentHistoryIndex > 0) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      const img = new Image();
      img.onload = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0);
      };
      img.src = maskHistory[currentHistoryIndex - 1];

      setCurrentHistoryIndex((prev) => prev - 1);
    }
  };

  const handleRedo = () => {
    if (currentHistoryIndex < maskHistory.length - 1) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      const img = new Image();
      img.onload = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0);
      };
      img.src = maskHistory[currentHistoryIndex + 1];

      setCurrentHistoryIndex((prev) => prev + 1);
    }
  };

  const clearMask = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Save clear state
    const imageData = canvas.toDataURL();
    setMaskHistory((prev) => [
      ...prev.slice(0, currentHistoryIndex + 1),
      imageData,
    ]);
    setCurrentHistoryIndex((prev) => prev + 1);
  };

  const generatePreview = () => {
    const previewCanvas = previewCanvasRef.current;
    const ctx = previewCanvas.getContext("2d");
    const originalImage = imageRef.current;

    // Set dimensions
    previewCanvas.width = width;
    previewCanvas.height =
      originalImage.offsetHeight * (width / originalImage.offsetWidth);

    // Clear canvas
    ctx.clearRect(0, 0, previewCanvas.width, previewCanvas.height);

    // Draw original image
    ctx.drawImage(
      originalImage,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
    );

    // Draw mask over it
    const maskCanvas = canvasRef.current;
    ctx.drawImage(maskCanvas, 0, 0);
  };

  const generateMaskedImage = () => {
    try {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const originalImage = imageRef.current;

      canvas.width = width;
      canvas.height =
        originalImage.offsetHeight * (width / originalImage.offsetWidth);

      // Create a temporary canvas for the mask
      const tempCanvas = document.createElement("canvas");
      const tempCtx = tempCanvas.getContext("2d");
      tempCanvas.width = canvas.width;
      tempCanvas.height = canvas.height;

      // Draw original image on temporary canvas
      tempCtx.drawImage(originalImage, 0, 0, canvas.width, canvas.height);

      // Draw the mask
      const maskCanvas = canvasRef.current;
      tempCtx.drawImage(maskCanvas, 0, 0);

      // Draw the combined result to the final canvas
      ctx.drawImage(tempCanvas, 0, 0);

      return canvas;
    } catch (error) {
      console.error("Error generating masked image:", error);
      return null;
    }
  };

  const handlePreview = () => {
    setShowPreview(!showPreview);
    if (!showPreview) {
      generatePreview();
    }
  };

  const handleDownload = () => {
    const maskedCanvas = generateMaskedImage();
    if (maskedCanvas) {
      try {
        const link = document.createElement("a");
        link.download = "masked-image.png";
        link.href = maskedCanvas.toDataURL("image/png");
        link.click();
      } catch (error) {
        console.error("Error downloading image:", error);
        alert(
          "Failed to download image. Please make sure the image is from the same domain or has CORS enabled.",
        );
      }
    }
  };

  return (
    <div className="flex flex-col items-center gap-4">
      <div className="relative">
        <img
          ref={imageRef}
          src={imageUrl}
          alt="maskable"
          crossOrigin="anonymous"
          className="w-full h-full object-contain rounded-lg"
          style={{
            width: `${width}px`,
            display: showPreview ? "none" : "block",
          }}
        />
        <canvas
          ref={canvasRef}
          className="absolute top-0 left-0 z-10"
          style={{
            width: `${width}px`,
            height: "100%",
            pointerEvents: isMaskMode ? "auto" : "none",
            cursor: isMaskMode ? "crosshair" : "default",
            display: showPreview ? "none" : "block",
          }}
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={stopDrawing}
          onMouseLeave={stopDrawing}
        />
        <canvas
          ref={previewCanvasRef}
          className="rounded-lg"
          style={{
            width: `${width}px`,
            display: showPreview ? "block" : "none",
          }}
        />
      </div>

      <div className="flex items-center space-x-4 bg-white p-4 rounded-lg shadow-md">
        <button
          onClick={handleUndo}
          disabled={currentHistoryIndex <= 0}
          className={`p-2 rounded-lg ${
            currentHistoryIndex > 0
              ? "bg-purple-100 text-purple-600 hover:bg-purple-200"
              : "bg-gray-200 text-gray-400 cursor-not-allowed"
          }`}
          title="Undo"
        >
          <Undo className="w-5 h-5" />
        </button>
        <button
          onClick={handleRedo}
          disabled={currentHistoryIndex >= maskHistory.length - 1}
          className={`p-2 rounded-lg ${
            currentHistoryIndex < maskHistory.length - 1
              ? "bg-purple-100 text-purple-600 hover:bg-purple-200"
              : "bg-gray-200 text-gray-400 cursor-not-allowed"
          }`}
          title="Redo"
        >
          <Redo className="w-5 h-5" />
        </button>
        <button
          onClick={() => {
            setIsMaskMode(!isMaskMode);
            if (isErasing) setIsErasing(false);
          }}
          className={`p-2 rounded-lg ${
            isMaskMode
              ? "bg-purple-600 text-white"
              : "bg-purple-100 text-purple-600 hover:bg-purple-200"
          }`}
          title="Draw Mode"
        >
          <Pen className="w-5 h-5" />
        </button>
        <button
          onClick={() => {
            if (isMaskMode) {
              setIsErasing(!isErasing);
            }
          }}
          disabled={!isMaskMode}
          className={`p-2 rounded-lg ${
            isErasing && isMaskMode
              ? "bg-purple-600 text-white"
              : isMaskMode
                ? "bg-purple-100 text-purple-600 hover:bg-purple-200"
                : "bg-gray-200 text-gray-400 cursor-not-allowed"
          }`}
          title="Eraser"
        >
          <Eraser className="w-5 h-5" />
        </button>
        <button
          onClick={clearMask}
          disabled={!isMaskMode}
          className={`p-2 rounded-lg ${
            isMaskMode
              ? "bg-red-100 text-red-600 hover:bg-red-200"
              : "bg-gray-200 text-gray-400 cursor-not-allowed"
          }`}
          title="Clear All"
        >
          Clear
        </button>
        <div className="w-px h-8 bg-gray-200" /> {/* Divider */}
        <button
          onClick={handlePreview}
          className={`p-2 rounded-lg ${
            showPreview
              ? "bg-blue-600 text-white"
              : "bg-blue-100 text-blue-600 hover:bg-blue-200"
          }`}
          title={showPreview ? "Show Original" : "Preview Mask"}
        >
          <Eye className="w-5 h-5" />
        </button>
        <button
          onClick={handleDownload}
          className="p-2 rounded-lg bg-green-100 text-green-600 hover:bg-green-200"
          title="Download Masked Image"
        >
          <Download className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

export default MaskingTool;
