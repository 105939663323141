
import { React, useState } from "react";
import {
  Images,
  Upload,
  UserSearchIcon,
  ChevronLeft,
  Camera,
  ChevronRight,
  Plus,
  ArrowRight,
  Image
} from "lucide-react";
import { DownloadButton } from "../Utils/DownloadButton.jsx";
import FileUploader from "./FileUploader.jsx";

const ImageProcessor = ({
  title,
  images,
  uploadTitle,
  onImageSelect,
  onImageUpload,
  onRun,
  selectedImage,
  selectedFilter,
  appliedFilter,
  isRunning,
  isDownloadReady,
  Header,
  type_run
}) => {
  const [showAllImages, setShowAllImages] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [UploadedImage, setUploadedImage] = useState({});

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImages = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex + 3 >= images.length ? 0 : prevIndex + 3,
    );
  };

  const prevImages = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex - 3 < 0 ? Math.max(images.length - 3, 0) : prevIndex - 3,
    );
  };

  const handleFilterSelect = (filter) => {
    onImageSelect(filter);

    setUploadedImage({
      newImage: filter,
      newImageUrl: filter,
    });
    setShowAllImages(false);
  };

  const handleShowMore = () => {
    setShowAllImages(true);
  };
  const handleImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const newImage = event.target.files[0];
      const newImageUrl = URL.createObjectURL(newImage);

      setUploadedImage({
        newImage: newImage,
        newImageUrl: newImageUrl,
      });
      onImageSelect(newImageUrl);
    }
  };
  const openFullscreenImage = (imageUrl) => {
    setFullscreenImage(imageUrl);
  };

  const closeFullscreenImage = () => {
    setFullscreenImage(null);
  };

  const handleModelReset = () => {
    // Clear the image URL
    setUploadedImage(prevState => ({
      ...prevState,
      newImageUrl: null
    }));
  };

  return (
    <div className="mt-8 border-2 p-4 shadow-lg rounded-xl">
      <h1 className="text-3xl mb-12 text-center text-[#1D3A72]">{Header}</h1>
      <div className="flex flex-col lg:flex-row items-center gap-4">
        {/* Left side - Filter Images */}
        <div className="w-full lg:w-1/3 rounded-lg p-4">
          <h2 className="text-lg text-center mb-4">{title}</h2>

          <div className="aspect-square w-full max-w-[300px] mx-auto mb-4">
            {UploadedImage.newImageUrl ? (
              <img
                src={UploadedImage.newImageUrl}
                alt="preview"
                className="w-full h-full object-contain rounded-lg"
              />
            ) : (
              <FileUploader handleImageUpload={handleImageUpload} />
            )}
          </div>
          <div className="text-center mb-4">
            <p>or Select a model from below</p>
          </div>
          <div className="flex justify-center mb-4">
            <div className="flex items-center gap-2 overflow-x-auto">
              {images
                .slice(currentImageIndex, currentImageIndex + 3)
                .map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    className={`w-20 h-20 object-cover rounded-lg cursor-pointer transition-all duration-300 ${
                      selectedFilter === img
                        ? "border-2 border-yellow-400"
                        : ""
                    }`}
                    alt={`Filter ${currentImageIndex + index + 1}`}
                    onClick={() => handleFilterSelect(img)}
                  />
                ))}
              <button
                className="w-20 h-20 p-2 bg-gray-200 text-blue-900 text-sm rounded-lg hover:bg-gray-300 transition-colors flex items-center justify-center"
                onClick={handleShowMore}
              >
                <span className="text-sm">Show More</span>
              </button>
            </div>
          </div>
          
          {UploadedImage.newImageUrl && (
            <div className="flex justify-center">
              <button
                onClick={handleModelReset}
                className="bg-red-500 px-12 py-2 rounded-lg text-white uppercase"
              >
                Reset
              </button>
            </div>
          )}
        </div>

        {/* Center Icons */}
        <div className="flex flex-col items-center justify-center">
          <Plus className="text-gray-500 w-12 h-12 mb-2" />
        </div>

        <div className="w-full lg:w-2/3  rounded-lg">
          <div className="flex flex-col md:flex-row  gap-4 ">
            {/* Left Image Upload Section */}
            <div className="w-full md:w-1/2 bg-white rounded-lg p-4">
              <h2 className="text-lg text-center mb-4">{uploadTitle}</h2>
              <div className="aspect-square w-full max-w-[300px] mx-auto mb-4">
                {selectedImage.blobImageUrl ? (
                  <img
                    src={selectedImage.blobImageUrl}
                    alt="preview"
                    className={`w-full h-full object-contain rounded-lg ${
                      selectedFilter === selectedImage.newImageUrl
                        ? "ring-4 ring-purple-600"
                        : ""
                    }`}
                  />
                ) : (
                  <FileUploader handleImageUpload={onImageUpload} />
                )}
              </div>

              <div className="text-center mb-4">
                <p>or Select a model from below</p>
              </div>
              <div className="flex justify-center mb-4">
                <div className="flex items-center gap-2 overflow-x-auto">
                  {images
                    .slice(currentImageIndex, currentImageIndex + 3)
                    .map((img, index) => (
                      <img
                        key={index}
                        src={img}
                        className={`w-20 h-20 object-cover rounded-lg cursor-pointer transition-all duration-300 ${
                          selectedFilter === img
                            ? "border-2 border-yellow-400"
                            : ""
                        }`}
                        alt={`Filter ${currentImageIndex + index + 1}`}
                        onClick={() => handleFilterSelect(img)}
                      />
                    ))}
                  <button
                    className="w-20 h-20 p-2 bg-gray-200 text-blue-900 text-sm rounded-lg hover:bg-gray-300 transition-colors flex items-center justify-center"
                    onClick={handleShowMore}
                  >
                    <span className="text-sm">Show More</span>
                  </button>
                </div>
              </div>
              
              {UploadedImage.newImageUrl && (
                <div className="flex justify-center">
                  <button
                    onClick={handleModelReset}
                    className="bg-red-500 px-12 py-2 rounded-lg text-white uppercase"
                  >
                    Reset
                  </button>
                </div>
              )}
            </div>

           <div className=" flex items-center">
            <ArrowRight className="text-gray-500 w-12 h-12" />
           </div>

            {/* Processed Image Section */}
            <div className="w-full md:w-1/2 bg-white rounded-lg p-4">
              <h2 className="text-lg text-center mb-4">Swapped Image</h2>
              <div className="aspect-square w-full max-w-[300px] mx-auto mb-4">
                {isRunning ? (
                  <div className="w-full h-full">
                    <div className="w-full h-full bg-white border-2 border-blue-900 rounded-lg flex items-center justify-center">
                      <h1 className="text-xl text-yellow-400">Generation in Process</h1>
                    </div>
                      <div class="loader"></div>
                  </div>
                ) : appliedFilter ? (
                  <img
                    src={appliedFilter}
                    alt="filtered"
                    className="w-full h-full object-contain rounded-lg cursor-pointer"
                    onClick={() => openFullscreenImage(appliedFilter)}
                  />
                ) : (
                  <div className="border-2 p-4 border-blue-900 h-full flex flex-col items-center justify-center rounded-lg">
                      <Image size={200}  className="text-gray-300"/>
                      <h1 className="text-sm text-gray-500">Nothing to see here yet!</h1>
                      <p className="text-sm text-gray-400">start by uploading images Run</p>
                  </div>
                )}
              </div>
              {isDownloadReady && (
                <div className="flex justify-center">
                  <DownloadButton imageUrl={appliedFilter} />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Fullscreen Image Modal */}
        {fullscreenImage && (
          <div
            onClick={closeFullscreenImage}
            className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
          >
            <div className="relative w-full h-full max-w-4xl max-h-4xl">
              <img
                src={fullscreenImage}
                alt="fullscreen"
                className="w-full h-full object-contain"
              />
            </div>
          </div>
        )}

        {/* Pop-up for all images */}
        {showAllImages && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg p-8 w-11/12 max-w-5xl max-h-[90vh] overflow-y-auto relative">
              <button
                className="absolute top-4 right-4 text-3xl font-bold text-gray-600 hover:text-gray-800"
                onClick={() => setShowAllImages(false)}
              >
                ×
              </button>
              <h3 className="text-2xl font-bold mb-6">Choose an Image</h3>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
                {images.map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    className={`w-full aspect-square object-cover rounded-lg cursor-pointer ${
                      selectedFilter === img
                        ? "ring-4 ring-purple-600"
                        : "ring-1 ring-gray-300 hover:ring-purple-400"
                    }`}
                    alt={`Filter ${index + 1}`}
                    onClick={() => handleFilterSelect(img)}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Run Button */}
      <div className="flex items-center justify-center mt-4">
        <button
          className={`w-full uppercase max-w-[400px] px-4 py-2 rounded-lg flex items-center justify-center text-lg font-semibold ${
            selectedFilter && selectedImage.blobImage
              ? "bg-[#E4AA0E] text-blue-900 hover:bg-yellow-500"
              : "bg-gray-300 text-gray-600 cursor-not-allowed"
          } transition-colors`}
          onClick={onRun}
          disabled={
            !selectedFilter || !selectedImage.blobImage || isRunning
          }
        >
          {isRunning ? "Processing..." : type_run}
        </button>
      </div>
    </div>
  );
};

export default ImageProcessor;