import React, { useState } from "react";
import { Upload, UploadCloud } from "lucide-react";

const FileUploader = ({ handleImageUpload }) => {
  const [isDragOver, setIsDragOver] = useState(false);

  // Drag and Drop Event Handlers
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);

    const files = e.dataTransfer.files;
    if (files.length > 0) {
      // Create a synthetic event object to match the onChange signature
      const syntheticEvent = {
        target: { files },
      };
      handleImageUpload(syntheticEvent);
    }
  };

  return (
    <div
      className={`flex border-2 ${isDragOver ? "border-purple-600" : "border-yellow-400"} rounded-lg p-2 justify-center items-center`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <div
        className={`border-2 flex flex-col items-center ${isDragOver ? "border-purple-600" : "border-dashed border-gray-300"} rounded-lg p-4 w-full max-w-md`}
      >
        <div className="flex items-center justify-center mb-4">
          <UploadCloud
            size={56}
            className={`${isDragOver ? "text-purple-600" : "text-gray-500"}`}
          />
        </div>
        <h1 className="text-sm font-medium mb-4">
          {isDragOver
            ? "Drop your file here"
            : "Select a file or drag and drop here"}
        </h1>
        <p className="text-gray-500 text-sm mb-6">
          JPG, PNG or WEBP files are supported
        </p>
        <div className="w-full flex items-center justify-center mt-4">
          <label className="relative inline-block">
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleImageUpload(e)}
              className="absolute inset-0 opacity-0 cursor-pointer w-full h-full"
            />
            <span
              className="bg-white text-[#6638B8B2] border border-[#6638B8B2] py-2 px-4 rounded-md cursor-pointer hover:bg-purple-700 transition-colors flex items-center justify-center"
              style={{ borderColor: "purple" }}
            >
              <span className="text-sm">SELECT FILE</span>
            </span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default FileUploader;
